import "../style.css"

import * as React from "react"
import { graphql } from 'gatsby'

const IndexPage = ({ data }) => {
  const screenshots = data.allFile.nodes;
  const [display, setDisplay] = React.useState('list')

  const onChange = (e) => {
    setDisplay(e.target.value);
  }

  React.useEffect(() => {
    document.querySelector('main').classList.remove('list')
    document.querySelector('main').classList.remove('grid')
    document.querySelector('main').classList.add(display)
  }, [display])

  return (
    <main>
      <title>Frequently Used</title>
      <div>
        <h1>Frequently Used</h1>

        <div className="displaySelector">
          <label htmlFor="display">display:</label>
          <select name="display" id="displayOptions" value={display} onChange={onChange}>
            <option value="list">list</option>
            <option value="grid">grid</option>
          </select>
        </div>
      </div>

      {screenshots.map(({ name, publicURL }) => (
        <div key={name}>
          <h3>{name}</h3>
          <img src={publicURL} alt={name}/>
        </div>
      ))}

      <footer>&copy; {(new Date().getFullYear())}</footer>
    </main>
  )
}

export const query = graphql`
  query MainQuery {
    allFile(sort: {fields: name, order: DESC}) {
      nodes {
        publicURL
        name
      }
    }
  }
`

export default IndexPage
